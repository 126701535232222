import * as React from "react"

import Layout from '../components/layout'
import SEO from '../components/seo'

import img1 from "../images/airtasker1.png";
import img2 from "../images/airtasker2.png";

const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Concept Homepage Redesign"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `website`]}
    />
    <div className="portfolio-header">
      <h1>Airtasker</h1>
      <h5>Homepage Redesign Concept</h5>
      <p>Suggestion of adjustments to homepage. Built with HTML, SCSS, jQuery and hosted on Netlify.</p>
    </div>
    <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://javier-presentation-for-airtasker.netlify.com/" target="_blank" rel="noopener noreferrer">View Presentation</a>
      <a className="btn btn-primary" href="https://javier-presentation-for-airtasker.netlify.com/presentation" target="_blank" rel="noopener noreferrer">View Presentation Document</a>
    </div>
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Homepage</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Help Centre</figcaption>
        <img src={img2} alt=""/>
      </figure>
    </div>

    

  </Layout>
)

export default PortfolioPage
